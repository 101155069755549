import * as React from "react"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import SignupForm from "../components/newsletter/SignupForm"


const NewsletterPage = () => {

  return (
    <Layout>
      <Seo title="Newsletter" description={`Stay posted on all things Lighter Than Air by subscribing to our newsletter.`} />
        <PageWrapper>

          <SignupForm className={`py-16 pb-14`} />
  
        </PageWrapper>
    </Layout>
  )
}

export default NewsletterPage
